<template>
  <div id="bg">
    <div class="search"></div>
    <el-table :data="tableData" :header-cell-style="{background:'#F7F8FA'}">
      <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="name" label="平台中文名称" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="pt" label="平台名称" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="pt_icon" label="平台图标" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="disable" label="状态" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <div v-if="scope.row.disable == 1" style="color:#33C933">开启</div>
          <div v-else style="color:#F33333">关闭</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250px" align="center">
        <template #default="scope">
          <span class="operation" @click="useGamePt(scope.row.id,scope.row.disable == 1 ? 0 : 1)">{{scope.row.disable == 1 ? "关闭" : "开启"}}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getGamePtList, useGamePt } from "@/api/game/pt";
export default {
  name: "gamePt",
  components: {},
  data() {
    return {
      search: {
        //搜索
      },
      tableData: [], //数据
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getList();
  },
  methods: {
    //获取数据
    getList() {
      getGamePtList([])
        .then((res) => {
          if (res.code == 0) {
            this.tableData = res.data;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 禁用启用
    useGamePt(id, disable) {
      useGamePt([
        { key: "pt_id", val: id },
        { key: "disable", val: disable },
      ])
        .then((res) => {
          if (res.code == 0) {
            this.$message({ message: res.data, type: "success" });
            this.getList();
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.form_box {
  overflow: hidden;
  margin-bottom: 18px;
}
.form_title {
  width: 30%;
  float: left;
  height: 32px;
  line-height: 32px;
}
.form_box >>> .el-input,
.form_box >>> .el-textarea {
  width: 60%;
}
.form_box >>> .el-select .el-input {
  width: 100%;
}
</style>